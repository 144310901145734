.header {
    background-color: transparent;
    top: 0;
    z-index: 101;
    width: 100%;
    position: fixed;
    transition: background-color .2s ease;
    height: 7.7rem;
    
    &--active {
        background-color: $color-white;
        @include elevation(1);
    }

    &__container {
        padding-top: 2rem;
        padding-bottom:  2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}