.service {
    @include elevation(2);
    transition: transform .2s, box-shadow .2s;
    display: flex;
    flex-direction: column;
    background: darken($color-white, 1%);
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
    height: 100%;

    &:hover {
        @include elevation(4);
        transition: transform .2s, box-shadow .2s;
    }

    &__icon {
        margin: 2rem 0;
        width: 5rem;
    }

    &__icon-container,
    &__title,
    &__copy {
        text-align: center;
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {
    .service {
        max-width: 100%;
    }
}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}