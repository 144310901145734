// GLOBAL STYLES
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 10px;
}

body {
    background: $color-white;
    color: $color-black;
    font-family: $font-body;
    font-weight: 400;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
}

a {
    text-decoration: none;
    font-weight: 400;
    color: $color-purple;
    
    &:active,
    &:hover {
        color: $color-green;
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading;
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

h1,
h2  {
    position: relative;
    display: inline-block;
    letter-spacing: .2rem;
    margin-bottom: 2rem;
    &:after {
        content: '';
        position: absolute;
        height: .3rem;
        width: 5rem;
        left: 0;
        bottom: -.75rem;
        background: $color-green;
    }
}

p {
    font-size: 1.4rem;
    font-weight: 200;
    line-height: 2.6rem;
    letter-spacing: .05rem;
    margin-bottom: 3rem;
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}