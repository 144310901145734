$menu-padding-x : 0 !default;
$menu-padding-y : 0 !default;
$menu-layer-width : 2.75rem !default;
$menu-layer-height : .3rem !default;
$menu-layer-spacing : .5rem !default;
$menu-layer-border-radius : .4rem !default;
$menu-hover-opacity : 1 !default;
$menu-active-layer-color : $color-purple !default;
$menu-active-hover-opacity: $menu-hover-opacity !default;

$menu-types: (spin) !default;

.menu {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: inherit;
    display: inline-block;
    font: inherit;
    margin: 0;
    overflow: visible;
    padding: $menu-padding-y $menu-padding-x;
    text-transform: none;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    z-index: 301;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: $menu-hover-opacity;
    }

    &.is-active {
        &:hover {
            opacity: $menu-active-hover-opacity;
        }

        .menu-inner,
        .menu-inner::before,
        .menu-inner::after {
            background-color: $menu-active-layer-color;
        }
    }
}

.menu-box {
    width: $menu-layer-width;
    height: $menu-layer-height * 3 + $menu-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.menu-inner {
    display: block;
    top: 50%;
    margin-top: $menu-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $menu-layer-width;
        height: $menu-layer-height;
        background-color: $color-purple;
        border-radius: $menu-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($menu-layer-spacing + $menu-layer-height) * -1;
    }

    &::after {
        bottom: ($menu-layer-spacing + $menu-layer-height) * -1;
    }
}

@if index($menu-types, spin) {
    .menu--spin {
        .menu-inner {
            transition-duration: 0.22s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

            &::before {
                transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
            }

            &::after {
                transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
        }

        &.is-active {
            .menu-inner {
                transform: rotate(225deg);
                transition-delay: 0.12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

                &::before {
                    top: 0;
                    opacity: 0;
                    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
                }

                &::after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
        }
    }
}