.text-area {
    @include elevation(2);
    border: none;
    outline: none;
    padding: 1.4rem 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 1rem;
    
    &__validation {
        @include validation;
    }
}