@import url(https://fonts.googleapis.com/css?family=Oswald|Open+Sans:300,400,600);
@font-face {
  font-family: 'Software Tester';
  src: url(/static/media/software_tester_7.66427bac.ttf); }

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

html {
  box-sizing: border-box;
  cursor: default;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  letter-spacing: .05rem; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

body {
  margin: 0; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

figcaption,
figure,
main {
  display: block; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

nav ol,
nav ul {
  list-style: none; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

dfn {
  font-style: italic; }

mark {
  background-color: #ffff00;
  color: #000000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

::-moz-selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  border-style: none; }

svg {
  fill: currentColor; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

button,
input,
optgroup,
select,
textarea {
  margin: 0; }

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details,
menu {
  display: block; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action: manipulation; }

[hidden] {
  display: none; }

[aria-busy="true"] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

[aria-disabled] {
  cursor: default; }

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-size: 10px; }

body {
  background: #FAF8F9;
  color: #272822;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh; }

a {
  text-decoration: none;
  font-weight: 400;
  color: #A280FB; }
  a:active, a:hover {
    color: #A6E22E;
    text-decoration: none; }

img {
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
  margin-top: 0;
  margin-bottom: 1rem;
  text-transform: uppercase; }

h1,
h2 {
  position: relative;
  display: inline-block;
  letter-spacing: .2rem;
  margin-bottom: 2rem; }
  h1:after,
  h2:after {
    content: '';
    position: absolute;
    height: .3rem;
    width: 5rem;
    left: 0;
    bottom: -.75rem;
    background: #A6E22E; }

p {
  font-size: 1.4rem;
  font-weight: 200;
  line-height: 2.6rem;
  letter-spacing: .05rem;
  margin-bottom: 3rem; }

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }
  .app__navigation {
    display: none; }
  .app__menu {
    display: inherit; }

@media only screen and (min-width: 768px) {
  .app__navigation {
    display: flex;
    align-items: center; }
  .app__menu {
    display: none; } }

.button {
  transition: box-shadow .2s;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.06), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.12);
  background: none;
  border: 0.1rem solid #FAF8F9;
  border-radius: .2rem;
  box-sizing: border-box;
  color: #272822;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 0.0625em;
  line-height: 2.4rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s;
  padding: 1.4rem 1.8rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 500; }
  .button:before {
    content: "";
    background: #FAF8F9;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left center;
            transform-origin: left center;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    z-index: -1; }
  .button:hover:before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }
  .button:active, .button:focus {
    outline: none; }
  .button--primary {
    border: 0.1rem solid #A6E22E;
    color: #FAF8F9; }
    .button--primary:hover {
      color: #272822; }
    .button--primary:before {
      background: #A6E22E; }
  .button--primary-inverted {
    background: #A6E22E;
    color: #FAF8F9; }
    .button--primary-inverted:hover {
      color: #272822; }
    .button--primary-inverted:before {
      border: 0.1rem solid #A6E22E;
      background: #FAF8F9; }
  .button--secondary {
    background: #FAF8F9;
    border: 0.1rem solid #A280FB;
    color: #A280FB; }
    .button--secondary:hover {
      color: #FAF8F9; }
    .button--secondary:before {
      background: #A280FB; }
  .button--secondary-inverted {
    border: 0.1rem solid #A280FB;
    background: #A280FB;
    color: #FAF8F9; }
    .button--secondary-inverted:hover {
      color: #A280FB; }
    .button--secondary-inverted:before {
      background: #FAF8F9; }
  .button--danger {
    border: 0.1rem solid #F92672;
    color: #FAF8F9; }
    .button--danger:before {
      background: #F92672; }

.footer__middle {
  background-color: #17b9d8;
  background-image: radial-gradient(circle at 100% 100%, #a6e22e 0%, rgba(166, 226, 46, 0) 100%), radial-gradient(circle at 0% 50%, rgba(23, 185, 216, 0.75) 0%, rgba(23, 185, 216, 0) 50%);
  transition: box-shadow .2s;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.08), 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 1rem; }

.footer__lower {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
  width: 100%; }

.footer__text {
  padding: 0; }

.footer__heading {
  margin-bottom: 6rem; }

@media only screen and (min-width: 240px) {
  .footer__lower {
    justify-content: center; } }

@media only screen and (min-width: 480px) {
  .footer__lower {
    align-items: flex-end;
    flex-direction: row;
    padding: 3rem 0; }
  .footer__text {
    padding: 1rem; }
    .footer__text:first-child {
      padding-left: 0; } }

@media only screen and (min-width: 768px) {
  .footer__lower {
    justify-content: flex-end; } }

.form {
  display: flex;
  flex-direction: column;
  width: 50rem;
  max-width: 90%; }

.header {
  background-color: transparent;
  top: 0;
  z-index: 101;
  width: 100%;
  position: fixed;
  transition: background-color .2s ease;
  height: 7.7rem; }
  .header--active {
    background-color: #FAF8F9;
    transition: box-shadow .2s;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.06), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.12); }
  .header__container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between; }

.home__hero {
  transition: box-shadow .2s;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.08), 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: #272822;
  position: relative; }
  .home__hero:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/static/media/hero1.f26d8908.png);
    background-repeat: no-repeat;
    -webkit-filter: opacity(25%);
            filter: opacity(25%);
    width: 100%;
    height: 100%;
    background-size: cover; }

.home__hero-button {
  transition: box-shadow .2s;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.06), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.home__icon-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-gap: 2rem;
  width: 100%; }

.home__icon {
  height: 7rem;
  width: 7rem; }

.home__icon-wrapper {
  text-align: center; }

.home__slider {
  position: relative;
  transition: box-shadow .2s;
  box-shadow: 0 1.9rem 3.8rem rgba(0, 0, 0, 0.15), 0 1.5rem 1.2rem rgba(0, 0, 0, 0.11); }
  .home__slider:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/static/media/hero2.ff2868b9.png);
    -webkit-filter: opacity(25%);
            filter: opacity(25%);
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover; }

@media only screen and (min-width: 768px) {
  .home__icon-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; } }

.logo {
  display: flex;
  align-items: center; }
  .logo__text {
    color: #A280FB !important;
    display: block;
    font-family: "Software Tester";
    font-weight: 400;
    letter-spacing: .2rem;
    font-size: 1.5rem; }

.main {
  flex: 1 1;
  padding-top: 7.2rem;
  transition: box-shadow .2s;
  box-shadow: 0 1.4rem 2.8rem rgba(0, 0, 0, 0.13), 0 1rem 1rem rgba(0, 0, 0, 0.11);
  z-index: 1; }
  .main--home, .main--not-found {
    padding-top: 0; }

.menu {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: inherit;
  display: inline-block;
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: 0 0;
  text-transform: none;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  z-index: 301; }
  .menu:focus {
    outline: none; }
  .menu:hover {
    opacity: 1; }
  .menu.is-active:hover {
    opacity: 1; }
  .menu.is-active .menu-inner,
  .menu.is-active .menu-inner::before,
  .menu.is-active .menu-inner::after {
    background-color: #A280FB; }

.menu-box {
  width: 2.75rem;
  height: 1.9rem;
  display: inline-block;
  position: relative; }

.menu-inner {
  display: block;
  top: 50%;
  margin-top: -0.15rem; }
  .menu-inner, .menu-inner::before, .menu-inner::after {
    width: 2.75rem;
    height: 0.3rem;
    background-color: #A280FB;
    border-radius: 0.4rem;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .menu-inner::before, .menu-inner::after {
    content: "";
    display: block; }
  .menu-inner::before {
    top: -0.8rem; }
  .menu-inner::after {
    bottom: -0.8rem; }

.menu--spin .menu-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .menu--spin .menu-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .menu--spin .menu-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.menu--spin.is-active .menu-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .menu--spin.is-active .menu-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .menu--spin.is-active .menu-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.navigation {
  color: #272822; }
  .navigation__link-container {
    display: inline-block;
    padding: 0 1rem; }
    .navigation__link-container:last-child {
      padding-right: 0; }
  .navigation__link {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    transition: all 0.3s ease;
    padding: 1rem 0;
    color: #A280FB; }
    .navigation__link:after {
      content: "";
      position: absolute;
      height: .2rem;
      background: #A6E22E;
      width: 0;
      left: 50%;
      bottom: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) all; }
    .navigation__link--active, .navigation__link:hover {
      color: #A6E22E; }
      .navigation__link--active:after, .navigation__link:hover:after {
        width: 100%; }

.navigation-mobile {
  background-color: #272822;
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: height .3s;
  overflow-x: hidden; }
  .navigation-mobile:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-filter: opacity(75%);
            filter: opacity(75%); }
  .navigation-mobile--active {
    height: 100%; }
  .navigation-mobile__link {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 2rem 0;
    letter-spacing: .0625em;
    transition: all .3s ease-in-out;
    visibility: hidden;
    opacity: 0; }
  .navigation-mobile--active .navigation-mobile__link {
    font-size: 1.75rem; }
    .navigation-mobile--active .navigation-mobile__link:nth-child(1) {
      visibility: visible;
      opacity: 1;
      transition-delay: .1s; }
    .navigation-mobile--active .navigation-mobile__link:nth-child(2) {
      visibility: visible;
      opacity: 1;
      transition-delay: .2s; }
    .navigation-mobile--active .navigation-mobile__link:nth-child(3) {
      visibility: visible;
      opacity: 1;
      transition-delay: .3s; }
    .navigation-mobile--active .navigation-mobile__link:nth-child(4) {
      visibility: visible;
      opacity: 1;
      transition-delay: .4s; }
    .navigation-mobile--active .navigation-mobile__link:nth-child(5) {
      visibility: visible;
      opacity: 1;
      transition-delay: .6s; }
  .navigation-mobile__span {
    transition: opacity .2s,  color .2s;
    position: relative;
    display: inline-block;
    margin: 1rem 0;
    outline: none;
    color: #A280FB; }
    .navigation-mobile__span:before {
      content: '|';
      margin-right: 1rem;
      -webkit-transform: translateX(2rem);
              transform: translateX(2rem); }
    .navigation-mobile__span:after {
      content: '|';
      margin-left: 1rem;
      -webkit-transform: translateX(-2rem);
              transform: translateX(-2rem); }
    .navigation-mobile__span:before, .navigation-mobile__span:after {
      transition: opacity .3s, -webkit-transform .3s;
      transition: transform .3s, opacity .3s;
      transition: transform .3s, opacity .3s, -webkit-transform .3s;
      opacity: 0;
      display: inline-block;
      color: #F92672; }
    .navigation-mobile__span:hover {
      transition: color .2s;
      color: #F92672; }
      .navigation-mobile__span:hover:before, .navigation-mobile__span:hover:after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1; }
  .navigation-mobile__menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; }

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }

.privacy-policy {
  margin: 2rem auto 10rem; }
  .privacy-policy ul {
    padding-left: 2rem;
    list-style: square; }

.scroll-icon {
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  transition: opacity .2s;
  z-index: 201; }
  .scroll-icon--visible {
    transition: opacity .2s;
    opacity: 1; }
  .scroll-icon__icon {
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 3rem;
    background: #A6E22E;
    border-radius: 10%;
    border: calc(3rem/8) solid #A6E22E;
    box-sizing: content-box; }
    .scroll-icon__icon:before {
      position: absolute;
      content: '';
      margin: auto;
      top: 0;
      bottom: calc(3rem / 10);
      left: 0;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: calc(3rem / 3) solid #fff;
      border-left: calc(3rem / 3) solid transparent;
      border-right: calc(3rem / 3) solid transparent; }

.section {
  transition: box-shadow .2s;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.08), 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 10rem 0; }
  .section--black {
    background: #272822;
    color: #FAF8F9; }

.service-group {
  display: grid;
  align-items: end;
  grid-template-columns: 100%;
  grid-gap: 2rem; }

@media only screen and (min-width: 768px) {
  .service-group {
    grid-template-columns: auto auto auto;
    margin-bottom: 2rem; } }

.service {
  transition: box-shadow .2s;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.08), 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);
  transition: box-shadow .2s, -webkit-transform .2s;
  transition: transform .2s, box-shadow .2s;
  transition: transform .2s, box-shadow .2s, -webkit-transform .2s;
  display: flex;
  flex-direction: column;
  background: #f8f5f6;
  padding: 2rem;
  border-radius: 1rem;
  width: 100%;
  height: 100%; }
  .service:hover {
    transition: box-shadow .2s;
    box-shadow: 0 1.4rem 2.8rem rgba(0, 0, 0, 0.13), 0 1rem 1rem rgba(0, 0, 0, 0.11);
    transition: box-shadow .2s, -webkit-transform .2s;
    transition: transform .2s, box-shadow .2s;
    transition: transform .2s, box-shadow .2s, -webkit-transform .2s; }
  .service__icon {
    margin: 2rem 0;
    width: 5rem; }
  .service__icon-container, .service__title, .service__copy {
    text-align: center; }

@media only screen and (min-width: 768px) {
  .service {
    max-width: 100%; } }

.slide {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  transition: opacity .3s, -webkit-transform .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s;
  opacity: 0;
  display: block; }
  .slide--visible {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 1;
    height: 100%;
    width: 100%; }

@media only screen and (min-width: 768px) {
  .slide {
    transition: opacity .3s, -webkit-transform .3s;
    transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s, -webkit-transform .3s;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem); }
    .slide--visible {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      width: 85%; } }

.slider {
  width: 100%;
  height: auto;
  position: relative; }
  .slider__next, .slider__previous {
    visibility: hidden; }
  .slider__view {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    height: auto; }
  .slider__timeline {
    padding: 0;
    font-size: 1.5rem;
    font-weight: bold;
    list-style: none;
    display: flex;
    flex-direction: column; }
  .slider__timeline-container {
    cursor: pointer; }
  .slider__timeline-item {
    margin: 2rem 0;
    display: inline-block; }
  .slider__timeline-circle {
    position: relative;
    margin: auto;
    text-align: center;
    cursor: pointer;
    color: #A280FB; }
    .slider__timeline-circle:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border-radius: 100%;
      border: 0.25rem solid #A280FB;
      height: 3rem;
      width: 3rem; }
    .slider__timeline-circle:after {
      transition: box-shadow .3s; }
    .slider__timeline-circle--active {
      color: #272822; }
      .slider__timeline-circle--active:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        height: 5rem;
        width: 5rem;
        border: 0.25rem solid #A280FB;
        border-radius: 100%;
        box-shadow: 0 0 1.5rem 0.5rem rgba(162, 128, 251, 0.25); }
  .slider__timeline-number {
    font-family: "Oswald", sans-serif;
    position: relative;
    z-index: 2;
    display: block;
    width: 3rem;
    height: 3rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    padding-top: .5rem;
    border-radius: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2; }
    .slider__timeline-number--active {
      background-color: #A280FB; }
  .slider__timeline-title {
    font-size: 1.5rem;
    display: inline-block;
    padding-left: 2rem; }
    .slider__timeline-title--active {
      color: #A280FB; }

@media only screen and (min-width: 768px) {
  .slider__view {
    height: 20rem; }
  .slider__next, .slider__previous {
    cursor: pointer;
    width: 2.5rem;
    visibility: visible;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .slider__next {
    right: 0; }
  .slider__previous {
    left: 0; }
  .slider__copy {
    margin-bottom: 0; }
  .slider__timeline {
    flex-direction: row;
    padding: 5rem 0 0;
    width: 100%; }
  .slider__timeline-item {
    width: 100%;
    display: block; }
  .slider__timeline-container {
    width: 100%;
    text-align: center; }
  .slider__timeline-title {
    display: block;
    padding: 1rem 0 0; } }

.text-area {
  transition: box-shadow .2s;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.08), 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);
  border: none;
  outline: none;
  padding: 1.4rem 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 1rem; }
  .text-area__validation {
    margin-bottom: 1rem;
    color: #F92672; }

.text-input {
  transition: box-shadow .2s;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.08), 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);
  border: none;
  outline: none;
  margin-bottom: 1rem;
  padding: 1.4rem 1.8rem;
  line-height: 2.4rem; }
  .text-input__validation {
    margin-bottom: 1rem;
    color: #F92672; }

