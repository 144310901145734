.main {
    flex: 1;
    padding-top: 7.2rem;
    @include elevation(4);
    z-index: 1;

    &--home,
    &--not-found {
        padding-top: 0;
    } 
}