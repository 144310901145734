// COLORS
// https://coolors.co/faf8f9-ccd6cf-898989-6c8c5f-4a4e4c
$color-green: #A6E22E;
$color-blue: #66D9EF;
$color-orange: #FD971F;
$color-pink: #F92672;
$color-purple: #A280FB;
$color-white: #FAF8F9;
$color-dark-grey: lighten(#272822, 30%);
$color-light-grey: lighten(#272822, 60%);
$color-black: #272822;

// FONTS
$font-heading: 'Oswald', sans-serif;
$font-body: 'Open Sans', sans-serif;
$font-logo: 'Software Tester';

// MEDIA QUERIES
$mobile-small:	240px;
$mobile-large:	320px;
$tablet-small:	480px;
$tablet-large:	768px;
$screen-small:	1024px;
$screen-large:	1280px;