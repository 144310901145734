.slider {
    width: 100%;
    height: auto;
    position: relative;

    &__next,
    &__previous {
        visibility: hidden;
    }

    &__view {
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        height: auto;
    }

    &__timeline {
        padding: 0;
        font-size: 1.5rem;
        font-weight: bold;
        list-style: none;
        display: flex;
        flex-direction: column;
    }
    
    &__timeline-container {
        cursor: pointer;
    }

    &__timeline-item {
        margin: 2rem 0;  
        display: inline-block; 
    }

    &__timeline-circle {
        position: relative;
        margin: auto;
        text-align: center;
        cursor: pointer;
        color: $color-purple;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;                
            transform: translate(-50%, -50%);
            border-radius: 100%;
            border: .25rem solid $color-purple;
            height: 3rem;
            width: 3rem;
        }

        &:after {
            transition: box-shadow .3s;
        }

        &--active {
            color: $color-black;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;                
                transform: translate(-50%, -50%);
                height: 5rem;
                width: 5rem;
                border: .25rem solid $color-purple;
                border-radius: 100%;
                box-shadow: 0 0 1.5rem .5rem rgba($color-purple, 0.25)
            }
        }
    }

    &__timeline-number {
        font-family: $font-heading;
        position: relative;
        z-index: 2;
        display: block;
        width: 3rem;
        height: 3rem;
        left: 50%;                
        transform: translate(-50%, 0);
        padding-top: .5rem;
        border-radius: 100%;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.2;

        &--active {
            background-color: $color-purple; 
        }
    }

    &__timeline-title {
        font-size: 1.5rem;
        display: inline-block;
        padding-left: 2rem;

        &--active {
            color: $color-purple;
        }
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {
    .slider {
        &__view {
            height: 20rem;
        }

        &__next,
        &__previous {
            cursor: pointer;
            width: 2.5rem;
            visibility: visible;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    
        &__next {
            right: 0;
        }

        &__previous {
            left: 0;
        }
        
        &__copy {
            margin-bottom: 0;
        }
        
        &__timeline {
            flex-direction: row;
            padding: 5rem 0 0;
            width: 100%;
        }

        &__timeline-item {
            width: 100%;
            display: block;
        }

        &__timeline-container {
            width: 100%;
            text-align: center;
        }

        &__timeline-title {
            display: block;
            padding: 1rem 0 0;
        }
    }
}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}