.scroll-icon {
    cursor: pointer;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    opacity: 0;
    transition: opacity .2s;
    z-index: 201;

    &--visible {
        transition: opacity .2s;
        opacity: 1;
    }

    &__icon {
        display: inline-block;
        position: relative;
        width: 3rem;
        height: 3rem;
        background: $color-green;
        border-radius: 10%;
        border: calc(3rem/8) solid $color-green;
        box-sizing: content-box;
        
        &:before {
            position: absolute;
            content: '';
            margin: auto;
            top: 0;
            bottom: calc(3rem / 10);
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            border-bottom: calc(3rem / 3) solid #fff;
            border-left: calc(3rem / 3) solid transparent;
            border-right: calc(3rem / 3) solid transparent
        }
    }        
}