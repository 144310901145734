.logo {
    display: flex;
    align-items: center;

    &__text {
        color: $color-purple !important;
        display: block;
        font-family: $font-logo;
        font-weight: 400;
        letter-spacing: .2rem;
        font-size: 1.5rem;
    }
}