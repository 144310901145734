.text-input {
    @include elevation(2);
    border: none;
    outline: none;
    margin-bottom: 1rem;
    padding: 1.4rem 1.8rem;
    line-height: 2.4rem;

    &__validation {
        @include validation();
    }
}