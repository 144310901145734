.slide {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    transition: transform .3s, opacity .3s;
    opacity: 0;
    display: block;

    &--visible {
        align-items: center;
        display: flex;
        justify-content: center;
        opacity: 1;
        height: 100%;
        width: 100%;
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {
    .slide {
        transition: transform .3s, opacity .3s;
        transform: translateX(10rem);
        
        &--visible {
            transform: translateX(0);
            width: 85%;
        }
    }
}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}
