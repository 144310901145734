.navigation-mobile {
    background-color: $color-black;
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: height .3s;
    overflow-x: hidden;

    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        filter: opacity(75%);
    }

    &--active {
        height: 100%;
    }

    &__link {
        font-size: 1rem;
        text-transform: uppercase;
        padding: 2rem 0;
        letter-spacing: .0625em;
        transition: all .3s ease-in-out; 
        visibility: hidden;
        opacity: 0;
    }

    &--active &__link {
        font-size: 1.75rem;
        
        &:nth-child(1) {
            visibility: visible;
            opacity: 1;
            transition-delay: .1s;
        }
    
        &:nth-child(2) {
            visibility: visible;
            opacity: 1;
            transition-delay: .2s;
        }
    
        &:nth-child(3) {
            visibility: visible;
            opacity: 1;
            transition-delay: .3s;   
        }
    
        &:nth-child(4) {
            visibility: visible;
            opacity: 1;
            transition-delay: .4s;
        }

        &:nth-child(5) {
            visibility: visible;
            opacity: 1;
            transition-delay: .6s;
        }
    }

    &__span {
        transition: opacity .2s, 
                    color .2s;
        position: relative;
        display: inline-block;
        margin: 1rem 0;
        outline: none;
        color: $color-purple;
        
        &:before {
            content: '|';
            margin-right: 1rem;
            transform: translateX(2rem);
        }

        &:after {
            content: '|';
            margin-left: 1rem;
            transform: translateX(-2rem);
        }
        &:before,
        &:after {
            transition: transform .3s, opacity .3s;
            opacity: 0;
            display: inline-block;
            color: $color-pink;
        }
        &:hover {
            transition: color .2s;
            color: $color-pink;

            &:before, 
            &:after {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    &__menu {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
