.button {
    @include elevation(1);
    background: none;
    border: .1rem solid $color-white;
    border-radius: .2rem;
    box-sizing: border-box;
    color: $color-black;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    letter-spacing: 0.0625em;
    line-height: 2.4rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s;
    padding: 1.4rem 1.8rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: 500;

    &:before {
        content: "";
        background: $color-white;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        transform-origin: left center;
        transition: transform 0.2s;
        z-index: -1;
    }

    &:hover {
        &:before {
            transform: scaleX(1);
        }
    }

    &:active,
    &:focus {
        outline: none;
    }

    &--primary {
        border: .1rem solid $color-green;
        color: $color-white;
        
        &:hover {
            color: $color-black;
        }

        &:before {
            background: $color-green;
        }
    }

    &--primary-inverted {
        background: $color-green;
        color: $color-white;
        
        &:hover {
            color: $color-black;
        }

        &:before {
            border: .1rem solid $color-green;
            background: $color-white;
        }
    }

    &--secondary {
        background: $color-white;
        border: .1rem solid $color-purple;
        color: $color-purple;
        &:hover {
            color: $color-white;
        }
        &:before {
            background: $color-purple;
        }
    }

    &--secondary-inverted {
        border: .1rem solid $color-purple;
        background: $color-purple;
        color: $color-white;
        
        &:hover {
            color: $color-purple;
        }

        &:before {
            background: $color-white;
        }
    }

    &--danger {
        border: .1rem solid $color-pink;
        color: $color-white;
    
        &:before {
            background: $color-pink;
        }
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}
