.home {
    &__hero {
        @include elevation(2);
        width: 100%;
        height: 100vh;
        top: 0;
        background-color: $color-black;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0; 
            left: 0;
            background-image: url('../static/hero1.png');
            background-repeat: no-repeat;
            filter: opacity(25%);
            width: 100%;
            height: 100%;
            background-size: cover;
        }
    }

    &__hero-button {
        @include elevation(1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__icon-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        grid-gap: 2rem;
        width: 100%;
    }

    &__icon {
        height: 7rem;
        width: 7rem;
    }

    &__icon-wrapper {
        text-align: center;
    }

    &__slider {
        position: relative;
        @include elevation(5);
        &:before {
            content: "";
            position: absolute;
            top: 0; 
            left: 0;
            background-image: url('../static/hero2.png');
            filter: opacity(25%);
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            background-size: cover;
        }
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {
    .home {
        &__icon-container {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }
}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}
