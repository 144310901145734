.section {
    @include elevation(2);
    @include flex-center;
    height: auto;
    padding: 10rem 0;

    &--black {
        background: $color-black;
        color: $color-white;
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}