@mixin links ($background, $text-color, $hover-color) {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    transition: all 0.3s ease;
    padding: 1rem 0;
    color: $text-color;

    &:after {
        content: "";
        position: absolute;
        height: .2rem;
        background: $background;
        width: 0;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
    }

    &--active,
    &:hover {
        color: $hover-color;

        &:after {
            width: 100%;
        }
    }
}

@mixin background-gradient ($color-001, $color-002) {
    background-color: $color-001;
    background-image: radial-gradient(circle at 100% 100%, rgba($color-002, 1) 0%, rgba($color-002, 0) 100%),
                        radial-gradient(circle at 0% 50%, rgba($color-001, 0.75) 0%, rgba($color-001, 0) 50%);
}

@mixin elevation($level: 1) {
    transition: box-shadow .2s;
    @if $level==1 {
        box-shadow: 0 .1rem .3rem rgba(0, 0, 0, 0.06), 0 .1rem .2rem rgba(0, 0, 0, 0.12);
    }

    @else if $level==2 {
        box-shadow: 0 .3rem .6rem rgba(0, 0, 0, 0.08), 0 .3rem .6rem rgba(0, 0, 0, 0.12);
    }

    @else if $level==3 {
        box-shadow: 0 1.0rem 2.0rem rgba(0, 0, 0, 0.10), 0 .6rem .6rem rgba(0, 0, 0, 0.12);
    }

    @else if $level==4 {
        box-shadow: 0 1.4rem 2.8rem rgba(0, 0, 0, 0.13), 0 1.0rem 1.0rem rgba(0, 0, 0, 0.11);
    }

    @else if $level==5 {
        box-shadow: 0 1.9rem 3.8rem rgba(0, 0, 0, 0.15), 0 1.5rem 1.2rem rgba(0, 0, 0, 0.11);
    }
}

@mixin validation {
    margin-bottom: 1rem;
    color: $color-pink;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}