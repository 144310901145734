.service-group {
    display: grid;
    align-items: end;
    grid-template-columns: 100%;
    grid-gap: 2rem;
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {
}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {
    .service-group {
        grid-template-columns: auto auto auto;
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}