.navigation {
    color: $color-black;

    &__link-container {
        display: inline-block;
        padding: 0 1rem;
                
        &:last-child {
            padding-right: 0;
        }
    }

    &__link {
        @include links($color-green, $color-purple, $color-green);
    }
}
