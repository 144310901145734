.footer {
    &__middle {
        @include background-gradient(darken($color-blue, 20%), $color-green);
        @include elevation(2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6rem 1rem;
    }

    &__lower {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem 0;
        width: 100%;
    }

    &__text {
        padding: 0;
    }

    &__heading {
        margin-bottom: 6rem;
    }
}


@media only screen and (min-width: $mobile-small) {
    .footer {
        &__lower {
            justify-content: center;
        }
    }
}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {
    .footer {
        &__lower {
            align-items: flex-end;
            flex-direction: row;
            padding: 3rem 0;
        }
    
        &__text {
            padding: 1rem;
            &:first-child {
                padding-left: 0;
            }
        }
    }
}

@media only screen and (min-width: $tablet-large) {
    .footer {
        &__lower {
            justify-content: flex-end;
        }
    }
}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}
