// FONTS
@import url('https://fonts.googleapis.com/css?family=Oswald|Open+Sans:300,400,600');
@font-face {
    font-family: 'Software Tester';
    src: url('../fonts/software_tester_7.ttf');
}
// BASE
@import "base/reset";
@import "base/grid";
@import "base/variables";
@import "base/mixins";
@import "base/global";
// PARTIALS
@import "partials/app";
@import "partials/button";
@import "partials/footer";
@import "partials/form";
@import "partials/header";
@import "partials/home";
@import "partials/logo";
@import "partials/main";
@import "partials/menu";
@import "partials/navigation";
@import "partials/navigation-mobile";
@import "partials/not-found";
@import "partials/privacy-policy";
@import "partials/scroll-icon";
@import "partials/section";
@import "partials/service-group";
@import "partials/service";
@import "partials/slide";
@import "partials/slider";
@import "partials/text-area";
@import "partials/text-input";
