.app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    &__navigation {
        display: none;
    }

    &__menu {
        display: inherit;
    }
}

@media only screen and (min-width: $mobile-small) {}

@media only screen and (min-width: $mobile-large) {}

@media only screen and (min-width: $tablet-small) {}

@media only screen and (min-width: $tablet-large) {
    .app {
        &__navigation {
            display: flex;
            align-items: center;
        }
    
        &__menu {
            display: none;
        }
    }
}

@media only screen and (min-width: $screen-small) {}

@media only screen and (min-width: $screen-large) {}